import { Button, Surface, SurfaceProps, Text } from '@quality24/design-system';
import { faRight } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';

import * as styles from './ContactLinkCard.module.scss';

export interface Props extends SurfaceProps {
  onClick: () => void;
}

/**
 * <ContactLinkCard>
 */
const ContactLinkCard: React.FunctionComponent<Props> = ({
  onClick,
  className,
  ...props
}) => {
  return (
    <Surface
      className={classNames(styles.wrapper, className, 'bg-gradient-45')}
      padding="sm"
      {...props}
    >
      <Text className="px-4" size="xl" weight="bold">
        Hospitais complexos exigem controles simplificados
      </Text>
      <Text className="mb-4">
        Transformamos a comunicação hospitalar e a experiência do paciente
      </Text>
      <Button
        variant="outline"
        size="lg"
        appendIcon={faRight}
        onClick={onClick}
      >
        Quero conhecer
      </Button>
    </Surface>
  );
};

export default ContactLinkCard;
