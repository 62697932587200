/**
 * Based of the react-share lib
 * https://github.com/nygardk/react-share
 */

const encodeObjectParams = (
  obj: Record<string, string | number | undefined | null>,
  start = '?',
) => {
  const params = Object.entries(obj)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`,
    );

  return params.length > 0 ? `${start}${params.join('&')}` : '';
};

const isMobileOrTablet = () => {
  return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
};

interface WindowConfig extends Record<string, any> {
  height: number;
  width: number;
}

/**
 * Opens a dialog for sharing
 */
export const windowOpen = (
  url: string,
  userConfig: WindowConfig,
  onClose?: (dialog: Window | null) => void,
) => {
  const config: Record<string, any> = {
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
    ...userConfig,
  };

  const shareDialog = window.open(
    url,
    undefined,
    Object.keys(config)
      .map((key) => `${key}=${config[key]}`)
      .join(', '),
  );

  if (onClose) {
    const interval = window.setInterval(() => {
      try {
        if (shareDialog === null || shareDialog.closed) {
          window.clearInterval(interval);
          onClose(shareDialog);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }, 1000);
  }

  return shareDialog;
};

//
// LinkedIn
//

interface ShareOptions {
  /** The url-encoded title value that you wish you use. */
  title?: string;
  /** The url-encoded description that you wish you use. */
  summary?: string;
  /** The url-encoded source of the content (e.g. your website or application name) */
  source?: string;
}

export const getLinkedinLink = (url: string, options: ShareOptions) => {
  return `https://www.linkedin.com/sharing/share-offsite${encodeObjectParams({
    url,
    mini: 'true',
    ...options,
  })}`;
};

export const getFacebookLink = (url: string, options: ShareOptions) => {
  return `https://www.facebook.com/sharer/sharer.php${encodeObjectParams({
    u: url,
    quote: options.summary,
    hashtag: '#quality24',
  })}`;
};

export const getTwitterLink = (url: string, options: ShareOptions) => {
  return `https://twitter.com/share${encodeObjectParams({
    url,
    text: options.title,
    via: options.source,
    hashtags: 'quality24',
  })}`;
};

export const getWhatsappLink = (url: string, options: ShareOptions) => {
  const origin = isMobileOrTablet() ? 'api' : 'web';
  return `https://${origin}.whatsapp.com/send${encodeObjectParams({
    text: `${options.title} ${url}`,
  })}`;
};

export const getEmailLink = (url: string, options: ShareOptions) => {
  return `mailto:${encodeObjectParams({
    subject: `Confira isso ${options.title}`,
    body: `${options.title} ${url}`,
  })}`;
};
