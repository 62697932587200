import includes from 'lodash/includes';
import orderBy from 'lodash/orderBy';

interface RankedBlogPost {
  article: ShortBlogPost;
  points: number;
}

// eslint-disable-next-line import/prefer-default-export
export class RelatedArticlesFactory {
  // Class members
  readonly articles: Array<ShortBlogPost>;

  readonly currentArticleSlug: string;

  maxArticles: number;

  category: string | undefined;

  tags: Array<string>;

  /**
   * Constructor
   */
  constructor(articles: Array<ShortBlogPost>, currentArticleSlug: string) {
    // Don't include the current article in articles list
    this.articles = articles.filter(
      (aArticle) => aArticle.slug !== currentArticleSlug,
    );
    this.currentArticleSlug = currentArticleSlug;

    // Set default values
    this.maxArticles = 3;
    this.category = undefined;
    this.tags = [];
  }

  // (4.) Builder pattern usage
  setMaxArticles(m: number): RelatedArticlesFactory {
    this.maxArticles = m;
    return this;
  }

  setTags(tagsArray: Array<string>): RelatedArticlesFactory {
    this.tags = tagsArray;
    return this;
  }

  setCategory(category: string): RelatedArticlesFactory {
    this.category = category;
    return this;
  }

  /**
   * Gets the related articles
   * @returns
   */
  getArticles(): Array<ShortBlogPost> {
    const { tags, category, articles, maxArticles } = this;
    const identityMap: Record<string, RankedBlogPost> = {};

    if (!tags || tags.length === 0) {
      // eslint-disable-next-line no-console
      console.error(
        'RelatedArticlesFactory: Tags not provided, use setTags().',
      );
      return [];
    }

    if (!category) {
      // eslint-disable-next-line no-console
      console.error(
        'RelatedArticlesFactory: Category not provided, use setCategory().',
      );
      return [];
    }

    function addToMap(article: ShortBlogPost): void {
      const { slug } = article;
      if (!Object.keys(identityMap).some((key) => key === slug)) {
        identityMap[slug] = {
          article,
          points: 0,
        };
      }
    }

    // For category matches, we add 2 points
    function addCategoryPoints(article: ShortBlogPost, uCategory: string) {
      const categoryPoints = 2;
      const { slug } = article;

      if (article.category.name === uCategory) {
        identityMap[slug].points += categoryPoints;
      }
    }

    // For tags matches, we add 1 point
    function addTagsPoints(article: ShortBlogPost, uTags: Array<string>) {
      const tagPoint = 1;
      const { slug } = article;

      const { tags: articleTags } = article.metadata;
      articleTags.forEach((aTag) => {
        if (includes(uTags, aTag.name)) {
          identityMap[slug].points += tagPoint;
        }
      });
    }

    function getIdentityMapAsArray() {
      return Object.keys(identityMap).map((slug) => identityMap[slug]);
    }

    // Map over all articles, adding points to map
    articles.forEach((article) => {
      addToMap(article);
      addCategoryPoints(article, category);
      addTagsPoints(article, tags);
    });

    // Convert the identity map to an array
    const arrayIdentityMap = getIdentityMapAsArray();

    // Use a lodash utility function to sort them
    // by points, from greatest to least
    const similarArticles = orderBy(arrayIdentityMap, ['points'], ['desc']);

    // Take the max number articles requested)
    return similarArticles
      .map((aArticle) => aArticle.article)
      .splice(0, maxArticles);
  }
}
