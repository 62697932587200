import React from 'react';
import classNames from 'classnames';
import { Surface, SurfaceProps } from '@quality24/design-system';

import * as styles from './Card.module.scss';

export interface Props extends SurfaceProps {
  children: React.ReactNode;
}

const Card: React.FunctionComponent<Props> = ({
  className,
  children,
  ...props
}) => {
  return (
    <Surface
      className={classNames(styles.wrapper, className)}
      backgroundColor="white"
      {...props}
    >
      {children}
    </Surface>
  );
};

export default Card;
