import {
  Button,
  Icon,
  Image,
  Link as ThemeLink,
} from '@quality24/design-system';
import { faBars, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faRight } from '@fortawesome/pro-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'gatsby';
import React from 'react';
import classNames from 'classnames';

import logo from '../../../assets/logos/logo-blog.webp';
import * as styles from './BlogNavbar.module.scss';

export interface Props {
  alwaysWithBackground?: boolean;
}

const Navbar: React.FunctionComponent<Props> = ({
  alwaysWithBackground = false,
}) => {
  const [onTop, setOnTop] = React.useState(true);
  const [show, setShow] = React.useState(false);

  /**
   * Listens to scroll changes in order to change navbar background
   */
  React.useEffect(() => {
    const handlePageScroll = () => {
      if (window.scrollY === 0 && !onTop) {
        setOnTop(true);
      } else if (window.scrollY !== 0 && onTop) {
        setOnTop(false);
      }
    };

    window.addEventListener('scroll', handlePageScroll);
    return () => window.removeEventListener('scroll', handlePageScroll);
  }, [onTop, setOnTop]);

  return (
    <nav
      className={classNames(styles.navbar, {
        'bg-gradient': alwaysWithBackground || !onTop,
      })}
    >
      <div className="container p-3 d-flex flex-items-center flex-justify-between">
        {/* Logo */}
        <Link type="button" to="/">
          <Image src={logo} alt="Logo Quality 24" width={160} />
        </Link>

        {/* Desktop menu view */}
        <div
          className={classNames(
            styles.desktopMenu,
            'd-none d-lg-flex flex-justify-end w-100',
          )}
        >
          <div className="d-flex gap-3">
            <Button className={styles.button} variant="outline">
              <Link className="d-flex flex-items-center gap-2" to="/">
                Quero conhecer
                <Icon icon={faRight} size="md" />
              </Link>
            </Button>

            <div className={styles.divisor} />
            <ThemeLink
              className={classNames(styles.link, styles.iconButton)}
              href={process.env.GATSBY_URL_LINKEDIN}
            >
              <Icon icon={faLinkedin} size="lg" />
            </ThemeLink>
          </div>
        </div>

        {/* Burger menu icon */}
        <Button
          className={classNames(
            styles.link,
            styles.burger,
            'd-block d-lg-none',
          )}
          variant="link"
          onClick={() => setShow((prev) => !prev)}
        >
          <Icon icon={!show ? faBars : faTimes} size="md" />
        </Button>
      </div>

      {/* Mobile menu */}
      {show && (
        <div
          className={classNames(
            styles.mobileMenu,
            'd-flex d-lg-none flex-column anim-slide-down',
          )}
        >
          <Button
            className={classNames(styles.button, 'mb-4')}
            variant="outline"
            style={{ alignSelf: 'flex-start' }}
          >
            <Link
              className="d-flex flex-items-center gap-2"
              to="/"
              onClick={() => setShow(false)}
            >
              Quero conhecer
              <Icon icon={faRight} size="md" />
            </Link>
          </Button>

          <div className={classNames(styles.divisor, 'mb-4')} />
          <ThemeLink
            className={classNames(styles.link, styles.iconButton)}
            href={process.env.GATSBY_URL_LINKEDIN}
          >
            <Icon icon={faLinkedin} size="lg" />
          </ThemeLink>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
