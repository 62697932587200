import React from 'react';
import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { Button, Icon } from '@quality24/design-system';
import classNames from 'classnames';

import {
  getEmailLink,
  getFacebookLink,
  getLinkedinLink,
  getTwitterLink,
  getWhatsappLink,
  windowOpen,
} from './utils';

export interface Props {
  className?: string;
  title: string;
  excerpt: string;
}

/**
 * <ShareBar>
 */
const ShareBar: React.FunctionComponent<Props> = ({
  className,
  title,
  excerpt,
}) => {
  // Checking if window exists due to it not being available at runtime
  const url = typeof window !== 'undefined' ? window.location.href : '';

  // Checking if window exists due to it not being available at runtime
  // const link = typeof window !== 'undefined' ? window.location.href : '';

  const handleClick = React.useCallback(
    (fn, skipWindow = false) => {
      const link = fn(url, {
        title,
        summary: excerpt,
        source: url,
      });

      if (skipWindow) {
        window.location.href = link;
        return;
      }

      const windowConfig = {
        height: 400,
        width: 500,
      };
      windowOpen(link, windowConfig);
    },
    [url, title, excerpt],
  );

  return (
    <div className={classNames('d-flex gap-2', className)}>
      <Button
        variant="link"
        size="lg"
        onClick={() => handleClick(getLinkedinLink)}
      >
        <Icon icon={faLinkedin} size="md" />
      </Button>
      <Button
        variant="link"
        size="lg"
        onClick={() => handleClick(getFacebookLink)}
      >
        <Icon icon={faFacebook} size="md" />
      </Button>
      <Button
        variant="link"
        size="lg"
        onClick={() => handleClick(getTwitterLink)}
      >
        <Icon icon={faTwitter} size="md" />
      </Button>
      <Button
        variant="link"
        size="lg"
        onClick={() => handleClick(getWhatsappLink)}
      >
        <Icon icon={faWhatsapp} size="md" />
      </Button>
      <Button
        variant="link"
        size="lg"
        onClick={() => handleClick(getEmailLink)}
      >
        <Icon icon={faEnvelope} size="md" />
      </Button>
    </div>
  );
};

export default ShareBar;
