import '@quality24/design-system/dist/index.css';

import {
  Badge,
  Button,
  Heading,
  Surface,
  Text,
} from '@quality24/design-system';
import { faRight } from '@fortawesome/pro-solid-svg-icons';
import { graphql, HeadFC, navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

import { RelatedArticlesFactory } from './RelatedArticleFactory';
import SEO from '../../containers/SEO';
import MainTemplate from '../MainTemplate';
import Card from '../../atoms/Card';
import ShareBar from '../../organisms/ShareBar';
import ContactLinkCard from '../../organisms/ContactLinkCard';
import BlogNavbar from '../../organisms/BlogNavbar';
import SquareGraphic from '../../../assets/graphic-1.inline.svg';
import * as styles from './BlogPostTemplate.module.scss';

interface QueryResult {
  contentfulPost: BlogPost;
  relatedPosts: {
    nodes: ShortBlogPost[];
  };
}

export interface Props {
  data: QueryResult;
}

/**
 * <BlogPostTemplate>
 */
const BlogPostTemplate: React.FunctionComponent<Props> = ({ data }) => {
  const post = data.contentfulPost;

  // Use a SimilarArticlesFactory to get my similar articles
  const relatedArticles = new RelatedArticlesFactory(
    data.relatedPosts.nodes || [],
    post.slug,
  )
    .setMaxArticles(3)
    .setTags(post.metadata.tags.map((n) => n.name))
    .setCategory(post.category.name)
    .getArticles();

  return (
    <MainTemplate navbarElement={BlogNavbar}>
      <div className="container">
        <div className={classNames(styles.wrapper, 'row g-4')}>
          {/* Post column */}
          <div className="col-12 col-lg-7">
            <Heading
              className="mb-2"
              as="h1"
              size="lg1"
              weight="bold"
              color="primary"
            >
              {post.title}
            </Heading>

            <div className="d-flex flex-items-center gap-2 mb-3">
              <Badge className={styles.badge}>
                <Text as="span" size="sm1" weight="bold" color="gray100">
                  {post.category.name}
                </Text>
              </Badge>

              <Text size="sm2" color="gray300">
                Postado em {moment(post.createdAt).format('DD/MM/YYYY')}
              </Text>
            </div>

            {/* Share bar - mobile only */}
            <div className="d-block d-lg-none mb-3">
              <Card padding="xs">
                <ShareBar
                  className="w-100 flex-justify-center"
                  title={post.title}
                  excerpt={post.excerpt}
                />
              </Card>
            </div>

            <div className="d-flex flex-column gap-3">
              {/* Excerpt */}
              <Text size="lg1" color="gray300">
                {post.excerpt}
              </Text>
              {/* Hero */}
              <GatsbyImage
                className={classNames(styles.image)}
                imgClassName="img"
                image={post.heroImage.gatsbyImageData}
                alt={post.title}
              />
              {/* Site link - mobile only */}
              <Surface
                className={classNames(
                  styles.websiteCall,
                  'bg-gradient-45 d-lg-none',
                )}
                padding="sm"
              >
                <Text size="lg1" weight="bold">
                  Hospitais complexos exigem controles simplificados
                </Text>
                <Button
                  variant="link"
                  appendIcon={faRight}
                  onClick={() => navigate('/')}
                >
                  Acessar o site
                </Button>
              </Surface>
              {/* Post */}
              <div
                className={styles.post}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: post.body.childMarkdownRemark.html,
                }}
              />
              {/* Contact link */}
              <ContactLinkCard onClick={() => navigate('/#contact-us')} />
            </div>
          </div>

          <div className="col-12 col-lg-5">
            <div className={styles.rightSideMenu}>
              {/* Share bar - desktop only */}
              <Card
                className="d-lg-block"
                padding="sm"
                style={{ display: 'none' }}
              >
                <Heading
                  className="mb-3"
                  as="h2"
                  size="sm2"
                  weight="bold"
                  color="primary"
                >
                  Compartilhe
                </Heading>
                <ShareBar title={post.title} excerpt={post.excerpt} />
              </Card>

              {/* Site link - desktop only */}
              <Surface
                className={classNames(
                  styles.websiteCall,
                  'bg-gradient-45 d-lg-block',
                )}
                padding="sm"
                style={{ display: 'none' }}
              >
                <Text size="lg1" weight="bold">
                  Hospitais complexos exigem controles simplificados
                </Text>
                <Button
                  variant="link"
                  appendIcon={faRight}
                  onClick={() => navigate('/')}
                >
                  Acessar o site
                </Button>
              </Surface>

              {/* Related articles */}
              {relatedArticles.length > 0 && (
                <Card padding="sm">
                  <Heading
                    className="mb-3"
                    as="h2"
                    size="sm2"
                    weight="bold"
                    color="primary"
                  >
                    Publicações relacionadas
                  </Heading>

                  <div className="d-flex flex-wrap gap-2">
                    {relatedArticles.map((article) => (
                      <div
                        key={article.slug}
                        className={classNames(
                          styles.relatedCard,
                          'row g-2 p-1 w-100',
                        )}
                        role="presentation"
                        onClick={() => navigate(`/blog/${article.slug}`)}
                      >
                        <div className="col-4">
                          <GatsbyImage
                            className={classNames(styles.image)}
                            imgClassName="img"
                            image={article.heroImage.gatsbyImageData}
                            alt={article.title}
                          />
                        </div>
                        <div className="col-8">
                          <Text weight="bold">{article.title}</Text>
                          <Text size="sm1">{article.excerpt}</Text>
                        </div>
                      </div>
                    ))}
                  </div>
                </Card>
              )}

              {/* Tags */}
              <Card padding="sm">
                <Heading
                  className="mb-3"
                  as="h2"
                  size="sm2"
                  weight="bold"
                  color="primary"
                >
                  Principais assuntos
                </Heading>

                <div className="d-flex flex-wrap gap-2">
                  {post.metadata.tags?.map((t) => (
                    <Badge key={t.name} className={styles.badge}>
                      <Text as="span" size="sm1" weight="bold" color="gray100">
                        {t.name}
                      </Text>
                    </Badge>
                  ))}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <SquareGraphic className={styles.graphic} />
    </MainTemplate>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      slug
      title
      excerpt
      createdAt

      metadata {
        tags {
          name
        }
      }
      category {
        name
      }

      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        file {
          url
        }
      }

      body {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
    }

    relatedPosts: allContentfulPost(
      sort: { order: DESC, fields: [createdAt] }
      limit: 1000
    ) {
      nodes {
        slug
        title
        excerpt
        createdAt

        heroImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        }

        body {
          childMarkdownRemark {
            timeToRead
          }
        }

        metadata {
          tags {
            name
          }
        }
        category {
          name
        }
      }
    }
  }
`;

export const Head: HeadFC<QueryResult> = ({ data }) => {
  const post = data.contentfulPost;

  return (
    <SEO
      title={post.title}
      description={post.excerpt}
      image={post.heroImage.file.url}
    />
  );
};
